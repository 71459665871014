import React from 'react';
import ReactDOM from 'react-dom/client';
import { ContextProvider } from './context/ContextProvider';
import { Home } from './Home';
import { LangProvider } from './lang/LangProvider';
import "./scss/styles.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ContextProvider>
    <LangProvider>
      <Home />
    </LangProvider>
  </ContextProvider>
  // </React.StrictMode>
);
