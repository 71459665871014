import React from 'react'
import { useState } from 'react'
import vikaLogo from '../assets/img/logo-vika-header.png'
import { Carrousel } from './Carrousel'
import { ContextConsumer } from '../context/ContextConsumer'
import { langConstants } from '../lang/LangProvider'
import { TT } from './TT'
import { HamIcon } from './HamIcon'
import { useEffect } from 'react'

const carrouselData = [
    {
        imgName: "carrousel-img1.png",
        title: <TT id={"asesorate_con_profesionales"} />,
        description: <TT id={"contamos_con"} />,
        CTAtext: <TT id={"contactanos"} />,
    },
    {
        imgName: "carrousel-img2.png",
        title: <TT id={"administra_tus_inversiones"} />,
        description: <TT id={"invertir_en_nuestros_fondos"} />,
        CTAtext: <TT id={"contactanos"} />,
    },
    {
        imgName: "carrousel-img3.png",
        title: <TT id={"necesitas_mejorar_el_rendimiento"} />,
        description: <TT id={"capitalizamos_rendimientos"} />,
        CTAtext: <TT id={"contactanos"} />,
    }
]

export const navLinks = [
    {
        caption: <TT id={"inicio"} />,
        link: "#inicio",
        navId: "title-inicio",
    },
    {
        caption: <TT id={"nosotros"} />,
        link: "#nosotros",
        navId: "title-quienes-somos",
    },
    {
        caption: <TT id={"productos"} />,
        link: "#productos",
        navId: "title-productos",
    },
    {
        caption: <TT id={"servicios"} />,
        link: "#servicios",
        navId: "title-familia",
    },
    {
        caption: <TT id={"contacto"} />,
        link: "#contacto",
        navId: "title-contacto",
    },
]

export const Header = () => {
    const [showModal, setShowModal] = useState(false);

    const [showFixedNav, setShowFixedNav] = useState(false);

    const { lang, setLang, selectedSection, setSelectedSection } = ContextConsumer();

    const translateES = () => {
        setLang(langConstants.esAR)
    }

    const translateEN = () => {
        setLang(langConstants.enUS)
    }

    const handleShowMenu = () => {
        setShowModal(status => !status)
    }

    const handleClickNavLink = (caption) => {
        setSelectedSection(caption);
        setShowModal(false);
    }

    useEffect(() => {
        const stickNavbar = () => {
            if (window !== undefined) {
                const condition = window.scrollY > window.innerHeight - 1
                setShowFixedNav(condition);
            }
        }
        window.addEventListener('scroll', stickNavbar);
        return () => window.removeEventListener('scroll', stickNavbar);
    }, [])

    return (
        <header id="inicio">
            <div className="header__main-container head-container">
                <div className={`header__nav-wrapper-fixed ${showFixedNav ? "show-fixed-nav" : ""}`}>
                    <div className="header__nav-container">
                        <a href={"#inicio"}> <img src={vikaLogo} alt="TODO" className="header__vika-logo"></img></a>
                        <nav className="header__nav-fulled">
                            <ul className="header__section-menu">
                                {navLinks.map((currentLink, index) => <li key={"navLi" + index} className={selectedSection == currentLink.caption ? "selected" : ""}>
                                    <a href={currentLink.link} onClick={() => setSelectedSection(currentLink.caption)}>
                                        {currentLink.caption}
                                    </a>
                                </li>)}
                            </ul>
                            <ul className="ul-separator header__translate-menu">
                                <li className={lang == langConstants.esAR ? "selected" : ""} onClick={translateES}>ES</li>
                                <li className={lang == langConstants.enUS ? "selected" : ""} onClick={translateEN}>EN</li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className={`header__nav-wrapper`}>
                    <div className="header__nav-container">
                        <a href={"#inicio"}> <img src={vikaLogo} alt="TODO" className="header__vika-logo"></img></a>
                        <nav className="header__nav-fulled">
                            <ul className="header__section-menu">
                                {navLinks.map((currentLink, index) => <li key={"navLi" + index} className={selectedSection == currentLink.caption ? "selected" : ""}>
                                    <a href={currentLink.link} onClick={() => setSelectedSection(currentLink.caption)}>
                                        {currentLink.caption}
                                    </a>
                                </li>)}
                            </ul>
                            <ul className="ul-separator header__translate-menu">
                                <li className={lang == langConstants.esAR ? "selected" : ""} onClick={translateES}>ES</li>
                                <li className={lang == langConstants.enUS ? "selected" : ""} onClick={translateEN}>EN</li>
                            </ul>
                        </nav>

                        <nav className="header__nav-collapsed">
                            <HamIcon activated={showModal} onClick={handleShowMenu} />

                            <div className={`header__nav-modal ${showModal ? "open-menu" : ""}`}>
                                <ul className="header__section-menu-collapsed">
                                    {navLinks.map((currentLink, index) => <li key={"navLiCollapsed" + index} className={selectedSection == currentLink.caption ? "selected" : ""}>
                                        <a href={currentLink.link} onClick={() => handleClickNavLink(currentLink.caption)}>
                                            {currentLink.caption}
                                        </a>
                                    </li>)}
                                </ul>

                                <ul className="ul-separator header__translate-menu-collapsed">
                                    <li className={lang == langConstants.esAR ? "selected" : ""} onClick={translateES}>ES</li>
                                    <li className={lang == langConstants.enUS ? "selected" : ""} onClick={translateEN}>EN</li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <Carrousel carrouselData={carrouselData} />
            </div>
        </header>
    )
}



