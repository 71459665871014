import React, { useState } from 'react'
import { useEffect } from 'react';

export const Hexagon = ({
    children,
    size,
    top,
    left,
    right,
    bottom,
    primary,
    opacity,
    id,
    hexagonState = [],
    isHexagonChangeState = []
}) => {
    const [hexHovered, setHexHovered] = hexagonState;

    const height = size + "px";
    const position = {
        position: "absolute",
        top,
        left,
        right,
        bottom,
        transition: "all 400ms ease"
    }

    const onMouseEnterHandler = () => {
        setHexHovered(id);
    }

    const onMouseLeaveHandler = () => {
        setHexHovered();
    }

    return (
        <div
            className={`hex-${primary ? id : "sec-" + id} ${hexHovered == id ? "hex-hovered" : hexHovered ? "hex-not-hovered" : ""}`}
            style={{ ...position }}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
        >
            <div className={"hex-container"} style={{ height: height, opacity: opacity ?? 1 }}>
                <svg className="hex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 255">
                    <polygon points="218.56 189.28 218.56 63.09 109.28 0 0 63.09 0 189.28 109.28 252.38 218.56 189.28"
                    />
                </svg>
                <div className="hex-info" style={{ width: size + "px" }}>
                    {children}
                </div>
            </div>
        </div>
    )
}
