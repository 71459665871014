import React, { createContext, useState } from 'react'
import { navLinks } from '../components/Header';

export const AppContext = createContext();

export const ContextProvider = ({ children }) => {

    const [lang, setLang] = useState("es-AR");
    const [selectedSection, setSelectedSection] = useState(navLinks[0].caption);

    return (
        <AppContext.Provider value={
            {
                lang,
                setLang,
                selectedSection,
                setSelectedSection
            }
        }>

            {children}
        </AppContext.Provider>
    )
}
