import { Header } from "./components/Header";
import { QuienesSomosSection } from "./sections/QuienesSomosSection";
import { PartnersSection } from "./sections/PartnersSection";
import { ProductosSection } from "./sections/ProductosSection";
import { ContactoSection } from "./sections/ContactoSection";
import { FamiliaFondosSection } from "./sections/FamiliaFondosSection";
import { WppChat } from "./components/WppChat";
import { useEffect } from "react";
import { useReveal } from "./customHooks/useReveal";
import { useShowSection } from "./customHooks/useShowSection";

export const Home = () => {

  const { disconectReveal } = useReveal();
  const { startShowSection, disconectShowSection } = useShowSection();

  useEffect(() => {
    startShowSection();

    return () => {
      disconectReveal();
      disconectShowSection();
    }
  }, [])

  return (
    <>
      <Header />
      <main>
        <QuienesSomosSection />
        <ProductosSection />
        {/* <PartnersSection /> */}
        <FamiliaFondosSection />
        <ContactoSection />

        <div className="rights">
          <p>© 2022 Vika Capital Financiera. Todos los derechos reservados.</p>
          <p>Diseñado por <a href="https://arsitta.com.ar/" target={"_blank"} rel="noreferrer">arsitta</a></p>
        </div>

      </main>

      <WppChat />
    </>
  );
}

