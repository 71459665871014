import React, { useEffect } from 'react'
import { SectionTitle } from '../components/SectionTitle'
import { TT } from '../components/TT'
import { useReveal } from '../customHooks/useReveal'

const CircularGraph = ({ index }) => {
    return (<>
        {index == 1 &&
            <svg width="80" height="80" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M44.0836 24.5374C44.0836 35.5779 35.1335 44.5279 24.0931 44.5279C13.0527 44.5279 4.10264 35.5779 4.10264 24.5374C4.10264 13.497 13.0527 4.54697 24.0931 4.54697C35.1335 4.54697 44.0836 13.497 44.0836 24.5374Z" fill="#24203E" stroke="#EAE3D9" strokeWidth="5.82246" />
                <path d="M22.9533 4.14575C12.3576 5.45148 4.16016 14.3291 4.16016 25.0867" stroke="#C0B69D" strokeWidth="7.41834" strokeLinejoin="round" />
            </svg>
        }
        {index == 2 &&
            <svg width="80" height="80" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24.0931" cy="22.9764" r="19.9905" fill="#24203E" stroke="#EAE3D9" strokeWidth="5.82246" />
                <path d="M4.16016 23.3498C4.16016 15.1949 8.77517 8.11841 15.535 4.58838" stroke="#C0B69D" strokeWidth="7.72605" strokeMiterlimit="10" />
            </svg>
        }
        {index == 3 &&
            <svg width="80" height="80" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="23.7357" cy="23.1737" r="19.9905" fill="#25203E" stroke="#EAE3D9" strokeWidth="5.82246" />
                <path d="M13.6919 5.77759C7.74231 9.59994 3.80078 16.2768 3.80078 23.8748" stroke="#C0B69D" strokeWidth="7.41834" strokeMiterlimit="10" />
            </svg>
        }
        {index == 4 &&
            <svg width="80" height="80" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="23.7357" cy="23.7318" r="19.9905" fill="#24203E" stroke="#EAE3D9" strokeWidth="5.82246" />
                <path d="M6.68686 13.2593C4.8492 16.5324 3.80078 20.3074 3.80078 24.3275" stroke="#C0B69D" strokeWidth="7.41834" strokeMiterlimit="10" />
            </svg>
        }
    </>
    )
}

const fondoCardData = [
    {
        title: <TT id={"familia_fondo_title_1"} />,
        description: <TT id={"familia_fondo_desc_1"} />,
        porcentaje: "8%",
        fondoNumber: "1",
        circularGraph: <CircularGraph index={1} />,
    },
    {
        title: <TT id={"familia_fondo_title_3"} />,
        description: <TT id={"familia_fondo_desc_3"} />,
        porcentaje: "4%",
        fondoNumber: "3",
        circularGraph: <CircularGraph index={3} />,

    },
    {
        title: <TT id={"familia_fondo_title_2"} />,
        description: <TT id={"familia_fondo_desc_2"} />,
        porcentaje: "6%",
        fondoNumber: "2",
        circularGraph: <CircularGraph index={2} />,
    },
    {
        title: <TT id={"familia_fondo_title_4"} />,
        description: <TT id={"familia_fondo_desc_4"} />,
        porcentaje: "2%",
        fondoNumber: "4",
        circularGraph: <CircularGraph index={4} />,

    }
]

const FondoCard = ({
    fondoNumber,
    title,
    description,
    porcentaje,
    circularGraph
}) => {
    return (
        <div className="familia-card__container">

            <div className="familia-card__fondo-info">
                <h4 className="familia-card__fondo-number">{fondoNumber}.</h4>
                <h3 className="familia-card__fondo-title" >{title}</h3>
            </div>

            <div className="familia-card__circular-graph">
                <div>
                    {circularGraph}
                    <p>{porcentaje}</p>
                </div>
            </div>

            <p className="familia-card__fondo-description">
                {description}
            </p>
        </div>
    )
}

export const FamiliaFondosSection = () => {
    const { addToReveal } = useReveal();

    useEffect(() => {
        addToReveal("#title-familia")
        addToReveal(".familia-card__container")
    }, [])

    return (
        <section id="servicios" className="familia__section">
            <div className="container">
                <SectionTitle
                    id={"title-familia"}
                    title={<TT id={"title_familia"} />}
                    subtitle={<TT id={"subtitle_familia"} />}
                    center
                />

                <div className="familia_grid">
                    {
                        fondoCardData.map((currentData) =>
                            <FondoCard
                                key={currentData.fondoNumber}
                                title={currentData.title}
                                fondoNumber={currentData.fondoNumber}
                                description={currentData.description}
                                porcentaje={currentData.porcentaje}
                                circularGraph={currentData.circularGraph}
                            />
                        )
                    }

                </div>
            </div>
        </section>
    )
}
