import React from 'react'

export const SectionTitle = ({ subtitle, title, center, id }) => {
    return (
        <div id={id} className={`section-title ${center ? "center" : ""}`}>
            <h3>{subtitle}</h3>
            <h1> {title} </h1>
        </div>
    )
}
