export const sendRequest = async (method, uri, params = {}) => {

    switch (method) {
        case "POST":
            return await fetch(uri, {
                method: "Post",
                body: JSON.stringify(params),
                headers: { 'Content-Type': 'application/json' },
            })
                .then(response => response.json())
                .then(data => data)


        case "GET":
            return await fetch(uri, {
                method: "Get",
            })
                .then(response => response.json())
                .then(data => data)

        default:
            console.log("falta aclarar method");
    }
}