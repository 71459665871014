import React, { useEffect, useState, useRef } from 'react'
import { TT } from './TT';

export const Carrousel = ({ carrouselData }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState(carrouselData[0].imgName);
    const [loaded, setLoaded] = useState(false);
    const [paused, setPaused] = useState(false);

    const timeBar = useRef()
    const imgRef = useRef();

    const stopTime = (e) => {
        setPaused(true);
    }

    const runTime = (e) => {
        setPaused(false);
    }

    const selectNewImage = (images, next = true) => {
        setLoaded(false);
        setSelectedIndex(selectedIndex => {
            const condition = next ? selectedIndex < images.length - 1 : selectedIndex > 0;
            const nextIndex = next ? (condition ? selectedIndex + 1 : 0) : condition ? selectedIndex - 1 : images.length - 1;
            setSelectedImage(images[nextIndex].imgName);
            return nextIndex;
        });

    };

    const previous = () => {
        selectNewImage(carrouselData, false);
    };

    const next = () => {
        selectNewImage(carrouselData);
    };


    const loadedReady = () => {
        setTimeout(() => {
            setLoaded(true)
        }, 200)
    }

    useEffect(() => {
        const observerFunc = (entries, observer) => {
            if (entries[0]?.contentRect?.height == imgRef?.current?.clientHeight) {
                next();
            }
        }

        const observador = new ResizeObserver(observerFunc);
        observador.observe(timeBar.current);
        return () => { observador.current?.disconnect(); }
    }, [])

    return (
        <div className="carrousel__container">
            <div className="carrousel__info" onMouseEnter={stopTime} onMouseLeave={runTime}>
                <div id="title-inicio" className={`carrousel__text-wrapper section-title ${loaded ? "show" : ""}`}>
                    <p className={`carrousel__count ${loaded ? "show" : ""}`}>
                        <span>{selectedIndex + 1}</span>/{carrouselData.length}
                    </p>
                </div>
                <div className={`carrousel__text-wrapper ${loaded ? "show" : ""}`}>
                    <h1 className={`carrousel__title ${loaded ? "show" : ""}`}> {carrouselData[selectedIndex].title} </h1>
                </div>
                <div className={`carrousel__text-wrapper ${loaded ? "show" : ""}`}>
                    <p className={`carrousel__description ${loaded ? "show" : ""}`}>
                        {carrouselData[selectedIndex].description}
                    </p>
                </div>
                <div className={`carrousel__text-wrapper ${loaded ? "show" : ""}`}>
                    <button className={`carrousel_cta btn ${loaded ? "show" : ""}`}> {carrouselData[selectedIndex].CTAtext} <i className={"btn-arrow"}></i></button>
                </div>

            </div>
            <div className="carrousel_buttons-container">
                <button className="carrousel__button carrousel-previous" onClick={previous}>
                    <p >
                        <TT id={"anterior"} />
                    </p>
                </button>

                <button className="carrousel__button carrousel-next" onClick={next}>
                    <div></div>
                    <p>
                        <TT id={"siguiente"} />
                    </p>
                </button>
            </div>

            {/* ABSOLUTE */}
            <div
                className={`carrousel_timebar ${loaded ? "show-bar" : ""}`}
                ref={timeBar}
                style={{
                    animationPlayState: paused ? "paused" : "running"
                }}
            >
            </div>
            <div className={`carrousel_img-container ${loaded ? "zoom" : ""} `}
                style={{
                    animationPlayState: paused ? "paused" : "running"
                }}
            >
                <img
                    ref={imgRef}
                    src={require(`../assets/img/${selectedImage}`)}
                    className={`carrousel__img ${loaded ? "show-img" : ""}`}
                    style={{
                        animationPlayState: paused ? "paused" : "running"
                    }}
                    onLoad={loadedReady}
                    alt="jeje"
                />
            </div>
        </div >
    )
}
