export const enUs = {
    /* Menu */
    "inicio": "Home",
    "servicios": "Servicies",
    "nosotros": "Nosotros",
    "contacto": "Contact",

    /* Carrousel */
    "asesorate_con_profesionales": "Get professional advice",
    "contamos_con": "We have services designed to maximize the return on your investments.",
    "contactanos": "CONTACTANOS",
    "administra_tus_inversiones": "Manage your investments easily and effectively",
    "invertir_en_nuestros_fondos": "Invest in our private investment funds and get results.",
    "necesitas_mejorar_el_rendimiento": "Does your money need speed?",
    "capitalizamos_rendimientos": "We capitalize on returns in real economy funds, improving our clients' return on investment.",

    "anterior": "PREVIOUS",
    "siguiente": "NEXT",

    /* Seccion Quienes Somos */
    "nuestra_mision": "NUESTRA MISION",
    "quienes_somos": "About us:",
    "un_equipo_de_consultores": "A team of consultants with more than 30 years in the financial market and investment banking. Our agreements with multiple international companies allow us to offer a broad investment portfolio.",
    "configuramos_relaciones": "We establish long-term relationships with our clients. We integrate your need and model customized solutions through our partnerships with the main stock exchange and investment banking companies.",

    /* Seccion Productos */
    "soluciones_financieras": "FINANCIAL SOLUTIONS",
    "productos": "Products",

    /* Seccion Partners */
    "nuestros_partners": "Own partners",
    "confian_en_nosotros": "CONFIAN EN NOSOSTROS",

    /* Seccion Familia */
    "title_familia": "title_familia_EN",
    "subtitle_familia": "subtitle_familia_EN",

    /* Seccion Contacto */
    "estas_a_un_paso": "ONE STEP AWAY FROM INVESTING BETTER",
    "horario_de_atencion": "Hours of business:",
    "telefono": "Phone:",
    "nuestras_oficinas": "Business Office:",
    "oficina_colegiales": "Oficinas Colegiales:",
    "oficina_centro": "Oficinas Centro:",
    "direccion_oficina_colegiales": "Ciudad de la paz 353 - floor 6th - CABA | Bs As | Argentina",
    "direccion_oficina_centro": "Maipú 311 - floor 19th - CABA | Buenos Aires | Argentina",
}