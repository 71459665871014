import React, { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl';
import { ContextConsumer } from '../context/ContextConsumer';

export const TT = ({ id }) => {

    const { lang } = ContextConsumer();
    const elementRef = useRef(0);

    useEffect(() => {
        elementRef.current = elementRef.current + 1;
    }, [lang])

    return (
        <span key={"trad" + elementRef.current} className={"fadeShow"}>
            <FormattedMessage id={id} />
        </span>
    )
}
