export const esAr = {
    /* Menu */
    "inicio": "Inicio",
    "servicios": "Servicios",
    "nosotros": "Nosotros",
    "contacto": "Contacto",

    /* Carrousel */
    "asesorate_con_profesionales": "Asesorate con profesionales",
    "contamos_con": "Contamos con servicios pensados para maximizar el rendimiento de sus inversiones.",
    "contactanos": "CONTACTANOS",
    "administra_tus_inversiones": "Administra tus inversiones de manera simple y efectiva",
    "invertir_en_nuestros_fondos": "Invertir en nuestros fondos de capitalización privados permite alcanzar objetivos y obtener resultados.",
    "necesitas_mejorar_el_rendimiento": "Necesitas mejorar el rendimiento de tus inversiones?",
    "capitalizamos_rendimientos": "Capitalizamos rendimientos en fondos de economía real mejorando el retorno de la inversión de nuestros clientes.",

    "anterior": "ANTERIOR",
    "siguiente": "SIGUIENTE",

    /* Seccion Quienes Somos */
    "nuestra_mision": "NUESTRA MISION",
    "quienes_somos": "Quienes somos:",
    "un_equipo_de_consultores": "Un equipo de consultores con más de 30 años en el mercado financiero y la banca de inversión. Nuestro convenios con múltiples compañías internacionales nos permiten ofrecer una amplia cartera de inversión.",
    "configuramos_relaciones": "Configuramos relaciones de largo plazo con nuestros clientes. Integramos su necesidad y modelamos soluciones a medida a través de nuestros partnerships con las principales sociedades de bolsa y la banca de inversión.",

    /* Seccion Productos */
    "soluciones_financieras": "SOLUCIONES FINANCIERAS",
    "productos": "Productos",

    /* Seccion Partners */
    "nuestros_partners": "Nuestros partners",
    "confian_en_nosotros": "CONFIAN EN NOSOSTROS",

    /* Seccion Familia */
    "title_familia": "Fondos de Capitalización Privada",
    "subtitle_familia": "PORTFOLIO VIKA CAPITAL",

    "familia_fondo_title_1": "Wealth Working Copper",
    "familia_fondo_desc_1": "Financiamiento del proceso de compra-fundición-compactación y exportación de chatarra de cobre.",

    "familia_fondo_title_2": "Wealth Working Fronting",
    "familia_fondo_desc_2": "Financiamiento de logística terrestre para la compra-venta de commodities.",
    
    "familia_fondo_title_3": "Wealth Working Real State",
    "familia_fondo_desc_3": "Financiamiento para desarrollos edilicios privados.",

    "familia_fondo_title_4": "Wealth Working ON's",
    "familia_fondo_desc_4": "Consiste en la compra de Obligaciones Negociables (ON).",


    /* Seccion Contacto */
    "estas_a_un_paso": "ESTAS A UN PASO DE INVERTIR MEJOR",
    "horario_de_atencion": "Horario de atencion:",
    "telefono": "Telefono:",
    "nuestras_oficinas": "Nuestras oficinas:",
    "oficina_colegiales": "Oficinas Colegiales:",
    "oficina_centro": "Oficinas Centro:",
    "direccion_oficina_colegiales": "Ciudad de la paz 353 - piso 6 - CABA | Bs As | Argentina",
    "direccion_oficina_centro": "Maipú 311 - piso 19 - CABA | Buenos Aires | Argentina",

}