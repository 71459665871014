import React from 'react'

export const Loader = ({ size }) => {

    return (
        <div className="loader"
            style={
                {
                    height: size ? size + "px" : "48px",
                    width: size ? size + "px" : "48px",
                    border: size ? `${size * 10 / 40}px solid` : "10px solid",
                    borderColor: "rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5)",
                }
            }

        ></div>
    )
}
