import React, { useEffect, useState } from 'react'
import { SectionTitle } from '../components/SectionTitle';
import { TT } from '../components/TT';
import { Hexagon } from "../components/Hexagon";
import { useReveal } from '../customHooks/useReveal';


export const QuienesSomosSection = () => {
    const { addToReveal } = useReveal();

    useEffect(() => {
        addToReveal(".quienes-somos__info")
        addToReveal(".quienes-somos__metrics")
    }, [])

    const hexagonState = useState();

    return (
        <section id="nosotros" className="quienes-somos__section container">
            <div className="quienes-somos__info">
                <SectionTitle
                    id={"title-quienes-somos"}
                    title={<TT id={"quienes_somos"} />}
                    subtitle={<TT id={"nuestra_mision"} />}
                />
                <p><TT id="un_equipo_de_consultores" /></p>
                <p><TT id="configuramos_relaciones" /></p>
            </div>
            <aside className="quienes-somos__metrics">
                {/* PRIMARY HEXAGONS */}
                <div className="quienes-somos__metrics-container">
                    <Hexagon size={140}
                        primary={true}
                        left={"0"}
                        top={"0"}
                        opacity="0.92"
                        id={1}
                        hexagonState={hexagonState}
                    >
                        <h1>+2.000</h1>
                        <p>empresas operan con nosotros</p>
                    </Hexagon>

                    <Hexagon size={160}
                        primary={true}
                        right={0}
                        top={"9rem"}
                        opacity="0.92"
                        id={2}
                        hexagonState={hexagonState}
                    >
                        <h1>+5.000</h1>
                        <p>clientes confían en nosotros</p>
                    </Hexagon>


                    <Hexagon size={140}
                        primary={true}
                        left={"20px"}
                        bottom={0}
                        opacity="0.92"
                        id={3}
                        hexagonState={hexagonState}
                    >
                        <h1>+30 años</h1>
                        <p>de experiencia</p>
                    </Hexagon>

                    <Hexagon id={1} size={70} left={"20px"} top={"110px"} opacity="0.15" hexagonState={hexagonState} />
                    <Hexagon id={2} size={95} left={"120px"} top={"200px"} opacity="0.15" hexagonState={hexagonState} />
                    <Hexagon id={3} size={60} left={"0px"} top={"200px"} opacity="0.15" hexagonState={hexagonState} />

                </div>
            </aside>
        </section >
    )
}
