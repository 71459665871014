import React, { useEffect, useState } from 'react'
import { SectionTitle } from '../components/SectionTitle'
import { TT } from '../components/TT'
import vikaFooter from "../assets/img/logo-vika-footer.png";
import { useReveal } from '../customHooks/useReveal';
import { sendRequest } from '../utils/request';
import { API_URLS } from '../utils/constants';
import { Formik, Field } from 'formik';
import { Loader } from '../components/Loader';

export const ErrorMessage = ({ touched, error }) => {
    return (
        <p className={`form-error ${touched && error ? "show" : ""}`}>
            {(touched && error) ? error : "asd"}
        </p>
    )
}

const expRegNames = /^[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*$/;
const expRegMail = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;

export const ContactoSection = () => {

    const { addToReveal } = useReveal();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("false");
    const [loading, setLoading] = useState(false);
    const [canSend, setCanSend] = useState(true);

    const handleOnSubmit = async (values, actions) => {
        setShowSuccess(false);
        setShowError(false);

        if (canSend) {
            try {
                setLoading(true)
                await sendRequest("POST", API_URLS.BACKEND_URL + API_URLS.SEND_MAIL, values);

                setShowSuccess(true)
                setCanSend(false);
                setTimeout(() => {
                    setCanSend(true);
                    setShowError(false);
                }, 10000);

            } catch (err) {
                setErrorMsg("No se pudo enviar el mensaje.");
                setShowError(true)

            } finally {
                setLoading(false)
            }
            setTimeout(() => setShowSuccess(false), 4000);
        } else {
            setErrorMsg("Ha habido un error al intentar enviar tu mensaje. Por favor, inténtelo de nuevo mas tarde.");
            setShowError(true);
        }
        actions.resetForm();
    }

    const handleValidate = (values) => {
        let error = {};
        values.nombre = values.nombre.trim();
        if (!values.nombre) {
            error.nombre = "*Nombre requerido";
        } else if (!expRegNames.test(values.nombre)) {
            error.nombre = "*Caracteres no validos";
        }

        values.apellido = values.apellido.trim();
        if (!values.apellido) {
            error.apellido = "*Apellido requerido";
        } else if (!expRegNames.test(values.apellido)) {
            error.apellido = "*Caracteres no validos";
        }

        values.correo = values.correo.trim();
        if (!values.correo) {
            error.correo = "*Correo requerido";
        } else if (!expRegMail.test(values.correo)) {
            error.correo = "*Correo invalido";
        }

        values.telefono = values.telefono.trim();
        if (!values.telefono) {
            error.telefono = "*Telefono requerido";
        }

        values.mensaje = values.mensaje.trim();
        if (!values.mensaje) {
            error.mensaje = "*Mensaje requerido";
        }

        return error;
    }

    useEffect(() => {
        addToReveal("#title-contacto")
        addToReveal(".contacto__info-container")
        addToReveal(".contacto__vika-logo")
        addToReveal(".contacto__form")
    }, [])

    return (
        <section id="contacto" className="contacto__section">
            <div className="container">
                <SectionTitle
                    id={"title-contacto"}
                    title={<TT id="contacto" />}
                    subtitle={<TT id="estas_a_un_paso" />}
                    center
                />
                <div className="contacto__grid">
                    <div className="contacto__info-container">
                        <div className="contacto__info-horarios">
                            <h3><TT id={"horario_de_atencion"} /></h3>
                            <div>
                                <h4><TT id={"telefono"} /></h4> (+54 9) 11 2478-9332<br></br>
                                <h4>Mail:</h4> info@vikafinanciera.com.ar<br></br>
                                <h4>Atencion por WhatsApp:</h4> Lu-Vi de 9hs a 18hs<br></br>
                            </div>
                        </div>
                        <div className="contacto__info-encontranos">
                            <h3><TT id={"nuestras_oficinas"} /></h3>
                            <div>
                                <h4><TT id={"oficina_colegiales"} /></h4> <br></br>
                                <TT id={"direccion_oficina_colegiales"} />
                                <br></br>
                            </div>
                            {/* <div>
                                <h4><TT id={"oficina_centro"} /></h4> <br></br>
                                <TT id={"direccion_oficina_centro"} />
                            </div> */}
                        </div>
                    </div>

                    <div className="contacto__vika-logo">
                        <img
                            src={vikaFooter}
                            alt="TODO"
                        />
                    </div>
                    <Formik

                        initialValues={{
                            nombre: "",
                            apellido: "",
                            correo: "",
                            telefono: "",
                            mensaje: ""
                        }}

                        validate={handleValidate}
                        onSubmit={(values, actions) => handleOnSubmit(values, actions)}
                    >
                        {({ errors, touched, handleSubmit }) => (<form className="contacto__form" onSubmit={handleSubmit}>
                            <div className="contacto__form-container">
                                <div className="w-50">
                                    <Field type="text" placeholder="Nombre" name="nombre" disabled={loading} />
                                    <ErrorMessage touched={touched.nombre} error={errors.nombre} />
                                </div>
                                <div className="w-50">
                                    <Field placeholder="Apellido" name="apellido" disabled={loading}></Field>
                                    <ErrorMessage touched={touched.apellido} error={errors.apellido} />
                                </div>
                                <div className="w-50">
                                    <Field type="text" placeholder="Correo" name="correo" disabled={loading}></Field>
                                    <ErrorMessage touched={touched.correo} error={errors.correo} />
                                </div>
                                <div className="w-50">
                                    <Field type="text" placeholder="Telefono" name="telefono" disabled={loading}></Field>
                                    <ErrorMessage touched={touched.telefono} error={errors.telefono} />
                                </div>
                            </div>

                            <div className="contacto__textarea-container">
                                <Field as="textarea" className="contacto__textarea" placeholder="Mensaje" name="mensaje" disabled={loading}></Field>
                                <ErrorMessage touched={touched.mensaje} error={errors.mensaje} />
                            </div>
                            <div className="contacto__form-actions">
                                <div className="contacto__form-response">
                                    {!loading && showSuccess && <p className="contacto__succes-msg">Mensaje enviado correctamente!</p>}
                                    {!loading && showError && <p>{errorMsg}</p>}
                                </div>
                                <button type="submit" className="contacto__form-btn">{
                                    loading ?
                                        <><div className="loading-button"><Loader size={"18"} /> <p>Enviando</p></div></>
                                        :
                                        <p>Enviar</p>
                                }</button>
                            </div>
                        </form>)}
                    </Formik>
                </div>
            </div>
        </section >
    )
}