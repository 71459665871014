import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import avatar from '../assets/img/avatar-wpp.png'
import { HamIcon } from './HamIcon'
import { Loader } from './Loader'
export const WppChat = () => {

    const [loadedModal, setLoadedModal] = useState(false);
    const [openingWppApp, setOpeningWppApp] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [sendMsg, setSendMsg] = useState(false);
    const [msg, setMsg] = useState("");
    const [showAgentMsg, setShowAgentMsg] = useState(false);
    const [renderAnimation, setRenderAnimation] = useState(0)
    const intervaloRef = useRef();

    const handClickFabWpp = () => {
        setSendMsg(false);
        setShowChat(true);
        setMsg("");
        if (loadedModal) setShowAgentMsg(true);
    }

    const onSubmiteMsg = (e) => {
        e.preventDefault();
        setSendMsg(true)
        setOpeningWppApp(true)
        setTimeout(() => {
            const phone = "5491124789332";
            const msgPredeterminado = "Mensaje enviado desde la web:"
            const url = `https://api.whatsapp.com/send/?phone=${phone}${msg ? "&text=" + msgPredeterminado + " " + msg : ""
                }& type=phone_number & app_absent=0"`;
            window.open(url, '_blank', 'noopener,noreferrer');
            setOpeningWppApp(false);
        }, 1500)

    }

    const onClickCloseModal = () => {
        setShowChat(false);
        setShowAgentMsg(false);
    }

    const onLoadModal = () => {
        setLoadedModal(true);
    }

    const crearIntervalo = () => {
        intervaloRef.current = setInterval(() => {
            setRenderAnimation(st => st + 1);
        }, 32000)
    }

    useEffect(() => {
        crearIntervalo();
        return (() => { clearInterval(intervaloRef.current); })
    }, [])

    return (
        <>
            <button key={renderAnimation} className="fab-wpp__container"
                onClick={handClickFabWpp}
                onMouseEnter={() => clearInterval(intervaloRef.current)}
                onMouseLeave={crearIntervalo}
            >
                <div className="fab-wpp__bg rubberBand">
                    <i className="wpp-icon" />
                </div>
                <div className={"fab-wpp__message show"}>{`Chatea con nosotros ->`}</div>

            </button>

            {
                <div className={`wpp__container ${showChat ? loadedModal ? "show-chat" : "" : ""}`}>
                    <div className="wpp__header">
                        <img className="wpp__avatar" src={avatar} alt="TODO" onLoad={onLoadModal} />
                        <div className="wpp__info">
                            <h3 className="wpp__agent-name">Agustin</h3>
                            <p className="wpp__agent-desc">Agente <span>(En linea)</span></p>
                        </div>

                        <button className="wpp__close-btn" onClick={onClickCloseModal}>
                            <HamIcon activated={true} size={"2rem"} />
                        </button>

                    </div>

                    <div className="wpp__chat-container">
                        <p className={`wpp__chat-msg ${showAgentMsg ? "show" : ""}`} style={{ transitionDelay: showAgentMsg ? ".5s" : "400ms" }} >Hola! 👋</p> <br></br>
                        <p className={`wpp__chat-msg ${showAgentMsg ? "show" : ""}`} style={{ transitionDelay: showAgentMsg ? "1.3s" : "400ms" }} >Mi nombre es Agustin</p> <br></br>
                        <p className={`wpp__chat-msg ${showAgentMsg ? "show" : ""}`} style={{ transitionDelay: showAgentMsg ? "1.4s" : "400ms" }} >En que te puedo ayudar?</p>

                        {sendMsg &&
                            <p className="wpp__info-msg">
                                Conectando con servidor...<br></br>
                                Abriendo Whatsapp...
                            </p>
                        }
                    </div>

                    <form className="wpp__actions-container" onSubmit={onSubmiteMsg}>
                        <input placeholder='Enviar mensaje...' value={msg} onChange={(e) => setMsg(e.target.value)}></input>
                        <button type="submite">
                            {openingWppApp ?
                                <Loader size={"22"} />
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514.21 457.06"><title>send-icon</title><path d="M38.58,489.78a48.67,48.67,0,0,1,6.19-23C66,427,87.61,387.3,108.58,347.31c6.24-11.91,15-18.24,28.34-19.82,48.11-5.73,96.18-11.85,144.26-17.83,7.76-1,15.52-2,23.29-2.93,4.13-.51,7.13-2.43,7-6.9-.08-4.18-3-6.05-6.89-6.54q-53.29-6.57-106.58-13.15c-21.48-2.66-43-5.23-64.43-8.11-10.35-1.39-18.18-6.69-23.25-16.14C88.31,214.78,66,173.8,44,132.65,31.39,109,41.35,81.75,65.57,73.37c10.32-3.57,20.62-1.56,30.23,2.56q88.89,38.13,177.6,76.66Q378.48,198,483.56,243.37c14.54,6.28,29.2,12.28,43.51,19,13.85,6.52,23.58,16.69,25.37,32.78,1.8,16.32-4.44,29-17.83,38.07-11.12,7.56-24,11.56-36.18,16.85q-124.49,54-249.1,107.78C198.1,480,146.91,502.29,95.55,524.14c-12.67,5.39-26,6.94-38.3-1.46C45.82,514.85,38.8,504.14,38.58,489.78Z" transform="translate(-38.53 -71.52)" /></svg>
                            }
                        </button>
                    </form>
                </div>
            }
        </>
    )
}



