import { useEffect, useRef } from "react";

export const useReveal = () => {
    const revealObserver = useRef()

    const funcionCallBack = (entries) => {
        entries.forEach(currentEntry => {
            currentEntry.target.classList.toggle("fade-in-down-show", currentEntry.isIntersecting)
            if (currentEntry.isIntersecting) revealObserver.current.unobserve(currentEntry.target)
        })
    }

    const addToReveal = (newElement) => {
        const arrElements = document.querySelectorAll(newElement);
        arrElements.forEach(currentElement => {
            currentElement.classList.add("fade-in-down")
            revealObserver.current.observe(currentElement);
        })
    }

    const disconectReveal = () => {
        revealObserver.current.disconnect()
        console.log("RevealObserver desconectado")
    }

    useEffect(() => {
        if (!revealObserver.current) {
            const observerOptions = {
                threshold: 0.75
            };
            revealObserver.current = new IntersectionObserver(funcionCallBack, observerOptions);
        }
    }, []);

    return { addToReveal, disconectReveal };
}
