import { useEffect, useRef, useState } from "react";
import { navLinks } from "../components/Header";
import { ContextConsumer } from "../context/ContextConsumer";

export const useShowSection = () => {
    const showSectionObserver = useRef()

    const { setSelectedSection } = ContextConsumer();
    const [sections, setSections] = useState()

    const funcionCallBack = (entries) => {
        entries.forEach(currentEntry => {
            setSections(st => {
                const currentElementId = st.findIndex(currentSt => currentSt.id == currentEntry.target.id)
                const newSt = [...st];
                newSt[currentElementId].visible = currentEntry.isIntersecting;
                let sectionVisible;
                if (currentEntry.isIntersecting) {
                    const arrSections = newSt.filter((currentSt, index) => index != currentElementId);
                    const areMultiVisibles = arrSections.some(currentSt => currentSt.visible);
                    if (!areMultiVisibles) {
                        sectionVisible = navLinks[currentElementId].caption
                        setSelectedSection(sectionVisible)
                    }
                } else {
                    const currentVisibleId = newSt.findIndex(currentSt => currentSt.visible)
                    if(currentVisibleId >= 0){
                        sectionVisible = navLinks[currentVisibleId].caption
                    } else{
                        sectionVisible = navLinks[currentElementId].caption
                    }
                    setSelectedSection(sectionVisible)
                }

                return newSt
            })
        })
    }

    const startShowSection = () => {
        const arrElements = document.querySelectorAll(".section-title");
        const arrElms = Array.from(arrElements)
        const arrIds = arrElms.map(currentElement => { return { id: currentElement.id, visible: false } })
        arrIds[0].visible = true;
        setSections(arrIds);
        arrElements.forEach(currentElement => {
            showSectionObserver.current.observe(currentElement);
        })
    }

    const disconectShowSection = () => {
        showSectionObserver.current.disconnect()
        console.log("RevealObserver desconectado")
    }

    useEffect(() => {
        if (!showSectionObserver.current) {
            const observerOptions = {
                rootMargin: "30px 0px 0px 0px",
                threshold: 0.7
            };
            showSectionObserver.current = new IntersectionObserver(funcionCallBack, observerOptions);
        }
    }, []);

    return { startShowSection, disconectShowSection };
}