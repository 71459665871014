import React from 'react'
import { IntlProvider } from 'react-intl';
import { ContextConsumer } from '../context/ContextConsumer';
import { enUs } from './en-US';
import { esAr } from "./es-AR";

export const langConstants = {
    esAR: "es-AR",
    enUS: "en-US",
}

export const LangProvider = ({ children }) => {

    const { lang } = ContextConsumer();

    const messages = {
        'es-AR': esAr,
        'en-US': enUs
    }

    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
            {children}
        </IntlProvider>
    )
}
